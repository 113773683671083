// Modules
import Modal from "./modules/modal.js"; // quite
import mobileMenu from "./modules/mobile-menu.js"; // quite
import tabs from "./modules/tabs.js"; // quite
import * as cookie from "./modules/cookie.js";

//Plugins
import * as objectfit from "./modules/objectfit.js";
import Swiper from "swiper/bundle";
import { Fancybox } from "@fancyapps/ui";

//Touch sniffer
if ("ontouchstart" in document.documentElement) {
  document.querySelector("html").classList.add("is-touch");
}

// Init functions on pageload
function init() {
  mobileMenu();
  new Modal();

  document.querySelector(".tabs") && tabs();
}

jQuery(document).ready(function ($) {
  init();

  const gallerySwiper = new Swiper(".gallery-container", {
    slidesPerView: 1,
    spaceBetween: 0,
    speed: 0,
    //effect: 'fade',
    loop: true,
    navigation: {
      nextEl: ".button-next",
      prevEl: ".button-prev",
    },
    breakpoints: {
      768: {
        slidesPerView: 1.25,
        spaceBetween: 40,
      },
      1024: {
        slidesPerView: 1.5,
        spaceBetween: 50,
      },
    },
  });

  const bgSwiper = new Swiper(".swiper-bg", {
    slidesPerView: 1,
    speed: 500,
    loop: true,
    autoplay: {
      delay: 5000,
    },
  });

  const coachesSwiper = new Swiper(".coaches-container", {
    slidesPerView: 1,
    spaceBetween: 32,
    speed: 0,
    loop: true,
    navigation: {
      nextEl: ".button-next",
      prevEl: ".button-prev",
    },
    breakpoints: {
      768: {
        slidesPerView: 2,
      },
    },
  });

  const teamSwiper = new Swiper(".team-container", {
    slidesPerView: 1,
    spaceBetween: 32,
    speed: 0,
    loop: false,
    navigation: {
      nextEl: ".button-next",
      prevEl: ".button-prev",
    },
    breakpoints: {
      768: {
        slidesPerView: 3,
        spaceBetween: 40,
      },
      1024: {
        slidesPerView: 4,
        spaceBetween: 50,
      },
    },
  });

  $(".tab-container").each(function (index) {
    // var ta = new Swiper($(this)[0], {
    // 	//Your options here:
    // });
    const tabSwiper = new Swiper($(this)[0], {
      loop: true,
      navigation: {
        nextEl: ".button-next",
        prevEl: ".button-prev",
      },
    });
  });

  const newsSwiper = new Swiper(".news-container", {
    slidesPerView: 1,
    spaceBetween: 0,
    speed: 0,
    //effect: 'fade',
    loop: true,
    navigation: {
      nextEl: ".button-next",
      prevEl: ".button-prev",
    },
    breakpoints: {
      768: {
        slidesPerView: 1.25,
        spaceBetween: 40,
      },
      1024: {
        slidesPerView: 1.5,
        spaceBetween: 50,
      },
    },
  });

  const filters = document.querySelectorAll(".filters li");
  const coaches = document.querySelectorAll(".team-container .swiper-slide");
  let currentActive = "";

  filters.forEach((filter) => {
    filter.addEventListener("click", handleFilter);
  });

  function handleFilter(e) {
    currentActive = e.currentTarget.dataset.location;

    if (e.currentTarget.hasAttribute("data-active")) {
      // show hidden coaches
      showCoaches(e.currentTarget);
    } else {
      // hide coaches of other locations
      hideCoaches(e.currentTarget);
    }
  }

  function hideCoaches(el) {
    removeActive(".filters li[data-active]");
    removeActive(".team-container .swiper-slide[data-active]");

    el.setAttribute("data-active", "");

    coaches.forEach((coach) => {
      // Split the data-location into an array and check if the currentActive location is included
      const locations = coach.dataset.location.split(' ');
      if (locations.includes(currentActive)) {
        coach.setAttribute("data-active", "");
      }
    });

    teamSwiper.update();
  }

  function showCoaches(el) {
    el.removeAttribute("data-active");

    coaches.forEach((coach) => {
      if (!coach.hasAttribute("data-active")) {
        coach.setAttribute("data-active", "");
      }
    });

    teamSwiper.update();
  }
  const mapOnLocationPage = document.querySelector("[data-map]");
  if (mapOnLocationPage) {
    const currentLocationMarker = document.querySelector("[data-location='" + mapOnLocationPage.dataset.map + "']");
    if (currentLocationMarker) {
      if (currentLocationMarker.querySelector(".location-inner")) {
        currentLocationMarker.querySelector(".location-inner").style.opacity = 1;
      }
    }
  }
  console.log("test");

  const removeActive = function (selector) {
    document.querySelectorAll(selector).forEach((el) => delete el.dataset.active);
  };

  const sections = document.querySelectorAll("[data-reveal]");

  const observer = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      if (entry.intersectionRatio > 0) {
        entry.target.setAttribute("data-animate", "");
      }
    });
  });

  if (window.innerWidth <= 540) {
    sections.forEach((section) => {
      section.removeAttribute("data-reveal");
    });
  } else {
    setTimeout(() => {
      sections.forEach((section) => {
        observer.observe(section);
      });
    }, 300);
  }

  // mobile cta button hideable
  function hideButtonWhenOver(scrollTarget) {
    // console.log(scrollTarget);
    if ($(window).scrollTop() + 200 >= scrollTarget.offset().top && $(window).scrollTop() <= scrollTarget.offset().top + scrollTarget.height()) {
      $(".mobile-cta").addClass("hide");
    } else {
      $(".mobile-cta").removeClass("hide");
    }
  }

  if ($("body").hasClass("page-id-4879")) {
    // zomerpromotie
    hideButtonWhenOver($("#inschrijven"));
    $(window).scroll(() => hideButtonWhenOver($("#inschrijven")));
  }
});
